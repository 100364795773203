import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";
import styled from "styled-components";

import { addGifToContent } from "../../utils";

const Wrapper = styled.div`
  h2 {
    text-transform: uppercase;
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }
  p {
    margin-bottom: 1.2rem;
  }
  a {
    color: #000;
    text-decoration: underline;
  }
  strong {
    font-weight: 500;
  }
`;

const SayHi = ({ image, content }) => {
  const img = getImage(image.localFile.childImageSharp);
  const serializer = addGifToContent();

  return (
    <Container className="my-5 pt-5">
      <Row className="align-items-center">
        <Col lg={6} data-sal="slide-up" data-sal-delay={300}>
          <Wrapper>
            <RichText render={content} htmlSerializer={serializer} />
          </Wrapper>
        </Col>
        <Col
          lg={5}
          className="ml-auto"
          data-sal="slide-up"
          data-sal-delay={400}
        >
          <GatsbyImage image={img} alt={image.alt || ""} />
        </Col>
      </Row>
    </Container>
  );
};

export default SayHi;
