import React from "react";
import { Container, Row, Col, Accordion, Card } from "react-bootstrap";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";
import { useIntl } from "gatsby-plugin-intl";
import styled from "styled-components";

import { addGifToContent } from "../../utils";

const Answer = styled.div`
  color: ${props => props.theme.colors.gray};
`;

const Faqs = ({ image, text, faqs }) => {
  const intl = useIntl();
  const img = getImage(image.localFile.childImageSharp);
  const serializer = addGifToContent();

  return (
    <Container>
      <Row>
        <Col lg={5} className="mb-4">
          <GatsbyImage image={img} alt={image.alt || ""} />
        </Col>
        <Col lg={6} className="ml-auto">
          <h2 className="text-uppercase h4 mb-4">
            {intl.formatMessage({ id: "faqs" })}
          </h2>
          <div className="mb-5">
            <RichText render={text} htmlSerializer={serializer} />
          </div>
          <Accordion defaultActiveKey="0">
            {faqs.map((faq, index) => (
              <Card key={faq.question.text} className="border-0 bg-transparent">
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey={index + ""}
                  className="bg-transparent border-0"
                >
                  <button className="bg-transparent border-0 w-100 text-left">
                    <h3>
                      {index + 1}. {faq.question.text}
                    </h3>
                  </button>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={index + ""}>
                  <Card.Body>
                    <Answer className="pl-4">
                      <RichText
                        render={faq.answer.raw}
                        htmlSerializer={serializer}
                      />
                    </Answer>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default Faqs;
