import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SayHi from "../components/contact/SayHi";
import FormLead from "../components/contact/FormLead";
import Faqs from "../components/contact/Faqs";

const Contacto = ({ data }) => {
  const { prismicContactPage } = data;

  return (
    <Layout>
      <SayHi
        image={prismicContactPage.data.greeting[0].image}
        content={prismicContactPage.data.greeting[0].content.raw}
      />
      <FormLead
        startProject={prismicContactPage.data.start_a_project.raw}
        investWithUs={prismicContactPage.data.invest_with_us.raw}
      />
      <Faqs
        image={prismicContactPage.data.faqs_image}
        text={prismicContactPage.data.faqs_text.raw}
        faqs={prismicContactPage.data.faqs}
      />
    </Layout>
  );
};

export const query = graphql`
  query($language: String!) {
    prismicContactPage(lang: { eq: $language }) {
      data {
        greeting {
          image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          content {
            raw
          }
        }
        start_a_project {
          raw
        }
        invest_with_us {
          raw
        }
        faqs_image {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        faqs_text {
          raw
        }
        faqs {
          question {
            text
          }
          answer {
            raw
          }
        }
      }
    }
  }
`;

export default Contacto;
