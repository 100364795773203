import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useIntl } from "gatsby-plugin-intl";
import { RichText } from "prismic-reactjs";

import { StyledButton } from "../Button";
import { FormModalContext } from "../../providers/FormModalProvider";
import { addGifToContent } from "../../utils";

const FormLead = ({ startProject, investWithUs }) => {
  const intl = useIntl();
  const { setModalState } = useContext(FormModalContext);
  const serializer = addGifToContent();

  return (
    <Container className="mb-5 pb-5">
      <Row className="mb-5 pb-4">
        <Col lg={6} className="mx-auto" data-sal="slide-up">
          <h2 className="text-uppercase h4 mb-3">
            {intl.formatMessage({ id: "start_project" })}
          </h2>
          <RichText render={startProject} htmlSerializer={serializer} />
          <StyledButton
            className="mt-3"
            onClick={e => {
              e.preventDefault();
              setModalState({
                isOpen: true,
                type: "project",
              });
            }}
          >
            {intl.formatMessage({ id: "interested" })}
          </StyledButton>
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="mx-auto" data-sal="slide-up">
          <h2 className="text-uppercase h4 mb-3">
            {intl.formatMessage({ id: "invest_with_us" })}
          </h2>
          <RichText render={investWithUs} htmlSerializer={serializer} />
          <StyledButton
            className="mt-3"
            onClick={e => {
              e.preventDefault();
              setModalState({
                isOpen: true,
                type: "investor",
              });
            }}
          >
            {intl.formatMessage({ id: "interested" })}
          </StyledButton>
        </Col>
      </Row>
    </Container>
  );
};

export default FormLead;
